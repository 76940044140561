// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import versionChange from './versionChange'

const rootReducer = {
  auth,
  navbar,
  layout,
  versionChange
}

export default rootReducer
