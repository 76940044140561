import { createSlice } from '@reduxjs/toolkit'

const versionChangeSlice = createSlice({
  name: 'versionChange',
  initialState: {
    selectedVersion: 'BSC', 
    availableVersions: ['BSC', 'ETH'] 
  },
  reducers: {
    selectVersion: (state, action) => {
      if (state.availableVersions.includes(action.payload)) {
        state.selectedVersion = action.payload
      }
    }
  }
})

export const { selectVersion } = versionChangeSlice.actions
export default versionChangeSlice.reducer